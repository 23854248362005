import React, { useState } from "react"
import PropTypes from "prop-types"
import Box from "./box"
import Inline from "./inline"
import Tippy from "@tippyjs/react/headless"

const defaultTitles = {
  "😊": "Lächelndes Gesicht",
  "😀": "Lachendes Gesicht",
  "😂": "Lachendes Gesicht mit Freudentränen",
  "😅": "Lachendes Gesicht mit Schweißperlen",
  "😎": "Lächelndes Gesicht mit Sonnenbrille",
  "😏": "selbstgefällig grinsendes Gesicht",
  "😐": "neutrales Gesicht",
  "😍": "lächelndes Gesicht mit herzförmigen Augen",
  "😉": "Zwinkerndes Gesicht",
  "😜": "Zwinkerndes Gesicht mit herausgestreckter Zunge",
  "😬": "Grimassen schneidendes Gesicht",
  "😮": "Gesicht mit offenem Mund",
  "😳": "Errötetes Gesicht mit großen Augen",
  "😒": "Verstimmtes Gesicht",
  "😢": "Weinendes Gesicht",
  "😭": "Heulendes Gesicht",
  "🤯": "explodierender Kopf",
  "😱": "Vor Angst schreiendes Gesicht",
  "🥳": "Party-Gesicht",
  "😡": "schmollendes Gesicht",
  "👺": "Teufel",
  "🤡": "Clown",
  "👏": "Klatschende Hände",
  "🙌": "Erhobene Hände",
  "💪": "Gebeugter Bizeps",
  "🤘": "Teufelsgruß",
  "👌": "OK-Zeichen",
  "✊": "Geballte Faust",
  "👎": "Daumen runter",
  "🙏": "Betende Hände",
  "👋": "Winkende Hand",
  "✨": "Glitzern",
  "🎉": "Party",
  "👀": "Augen",
  "🌞": "Sonnengesicht",
  "🌅": "Sonnenaufgang",
  "🌃": "Nacht mit Sternen",
  "🌙": "Mondsichel",
  "💦": "Wassertropfen",
  "🍴": "Gabel und Messer",
  "🔦": "Taschenlampe",
  "💥": "Explosion",
  "🔥": "Feuer",
  "🍇": "Trauben",
  "🎂": "Geburtstagskuchen",
  "💰": "Geldsack",
  "🥇": "Goldmedallie",
  "🚨": "Polizeilicht",
  "💡": "Glühbirne",
  "💖": "Herz",
  "🎭": "Masken",
  "🐴": "Pferdekopf",
  "🍺": "Bierkrug",
  "🌲": "Nadelbaum",
  "⛰": "Berg",
  "🛣": "Autobahn",
  "🛤": "Bahngleis",
  "🏰": "Schloss",
  "🥰": "lächelndes Gesicht mit Herzen",
  "🥶": "frierendes Gesicht",
  "😚": "küssendes Gesicht mit geschlossenen Augen",
  "🤔": "nachdenkendes Gesicht",
  "🤐": "Gesicht mit Reißverschlussmund",
  "🤪": "irres Gesicht",
  "🥴": "schwindeliges Gesicht",
  "🤧": "niesendes Gesicht",
  "🤮": "kotzendes Gesicht",
  "😇": "lächelndes Gesicht mit Heiligenschein",
  "🥵": "schwitzendes Gesicht",
  "😧": "qualvolles Gesicht",
  "🤨": "Gesicht mit hochgezogenen Augenbrauen",
  "😪": "schläfriges Gesicht",
  "😫": "müdes Gesicht",
  "🤩": "überwältigt",
  "😤": "schnaubendes Gesicht",
  "😩": "erschöpftes Gesicht",
  "😨": "ängstliches Gesicht",
  "🥺": "bettelndes Gesicht",
  "🧐": "Gesicht mit Monokel",
  "🤭": "verlegen kicherndes Gesicht",
  "🤫": "ermahnendes Gesicht",
  "🤗": "Gesicht mit umarmenden Händen",
  "😴": "schlafendes Gesicht",
  "😔": "nachdenkliches Gesicht",
  "😞": "enttäuschtes Gesicht",
  "🥱": "gähnendes Gesicht",
  "🤒": "Gesicht mit Fieberthermometer",
  "☝️": "nach oben weisender Zeigefinger von vorne",
  "👍": "Daumen hoch",
  "✌️": "Victory-Geste",
}

const TextPicker = ({
  id,
  set,
  sizePicker,
  fontWeightPicker,
  fontSizePicker,
  sizeButton,
  fontSizeButton,
}) => {
  const [selectionVisible, setSelectionVisible] = useState(false)
  const [selectedText, setSelectedText] = useState(null)

  // see https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
  let focusTimeout = null

  const onSelect = ({ selected }) => {
    setSelectedText(selected)
    hideSelection()
  }

  const toggleSelection = (event) => {
    event.preventDefault()
    setSelectionVisible(!selectionVisible)
  }

  const hideSelection = () => {
    setSelectionVisible(false)
  }

  const setWithTitles = set.map((item) => {
    if (typeof item === "object" && item.title && item.content) {
      return item
    }
    return { title: defaultTitles[item] || undefined, content: item }
  })

  return (
    <Box
      as="span"
      sx={{ display: "inline-flex" }}
      onBlur={() => {
        focusTimeout = setTimeout(() => {
          hideSelection()
        })
      }}
      onFocus={() => {
        clearTimeout(focusTimeout)
      }}
    >
      <Tippy
        interactive={true}
        visible={selectionVisible}
        onClickOutside={hideSelection}
        render={(attrs) => (
          <Box
            sx={{
              bg: "background",
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "contrast",
              p: 3,
              display: "inline-block",
              maxWidth: "320px",
              borderRadius: "lg",
            }}
            tabIndex="-1"
            {...attrs}
          >
            <Inline space={0} alignX="center">
              {setWithTitles.map(({ title, content }, i) => (
                <Box
                  key={`picker-option-${i}`}
                  as="button"
                  title={title}
                  cursor="pointer"
                  sx={{
                    appearance: "none",
                    borderRadius: "lg",
                    width: sizePicker,
                    height: sizePicker,
                    fontSize: fontSizePicker,
                    display: "inline-flex",
                    fontFamily: "sans",
                    fontWeight: fontWeightPicker || null,
                    alignItems: "center",
                    justifyContent: "center",
                    bg: selectedText === content ? "muted" : "transparent",
                    "&:hover, &:focus": {
                      bg: "muted",
                    },
                  }}
                  onClick={() => {
                    onSelect({ selected: content })
                  }}
                >
                  {content}
                </Box>
              ))}
            </Inline>
          </Box>
        )}
      >
        <Box
          as="button"
          onClick={toggleSelection}
          sx={{
            borderRadius: "lg",
            borderWidth: 2,
            borderColor: "contrast",
            cursor: "pointer",
            appearance: "none",
            bg: "background",
            width: sizeButton,
            height: sizeButton,
            fontSize: fontSizeButton,
            fontFamily: "sans",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover, &:focus": {
              bg: "border",
            },
          }}
        >
          {selectedText || "?"}

          <Box
            readOnly
            as="input"
            id={id}
            name={id}
            value={selectedText || ""}
            sx={{
              position: "absolute",
              opacity: 0,
              zIndex: -1,
              width: 1,
              height: 1,
              overflow: "hidden",
            }}
          />
        </Box>
      </Tippy>
    </Box>
  )
}

TextPicker.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium"]),
  set: PropTypes.array,
}

TextPicker.defaultProps = {
  sizeButton: 12,
  fontSizeButton: 6,
  sizePicker: 10,
  fontSizePicker: 5,
  set: [
    "😊",
    "😀",
    "😂",
    "😅",
    "😎",
    "😏",
    "😐",
    "😍",
    "😉",
    "😜",
    "😬",
    "😮",
    "😳",
    "😒",
    "😢",
    "😭",
    "🤯",
    "😱",
    "🥳",
    "😡",
    "👺",
    "🤡",
    "👏",
    "🙌",
    "💪",
    "🤘",
    "👌",
    "✊",
    "👎",
    "🙏",
    "👋",
    "✨",
    "🎉",
    "👀",
    "🌞",
    "🌅",
    "🌃",
    "🌙",
    "💦",
    "🍴",
    "🔦",
    "💥",
    "🔥",
    "🍇",
    "🎂",
    "💰",
    "🥇",
    "🚨",
    "💡",
    "💖",
    "🎭",
    "🐴",
    "🍺",
    "🌲",
    "⛰",
    "🛣",
    "🛤",
    "🏰",
  ],
}

export default TextPicker
