import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Note from "../../../../../components/note"
import Columns from "../../../../../components/columns"
import Column from "../../../../../components/column"
import Paragraph from "../../../../../components/paragraph"
import Text from "../../../../../components/text"
import Paper from "../../../../../components/paper"
import TaskSidebar from "../../../../../components/task-sidebar"
import TextPicker from "../../../../../components/text-picker"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "tagebuch",
}

const Page = () => {
  const { submitForm, getAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const previousAnswer = getAnswer({ ...taskProps, taskId: "tagebuch-01" })

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
          taskId: "tagebuch-02",
        })
        navigate(
          "/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/tagebuch/aufgabe-3"
        )
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
      progress={progress}
    >
      <Seo title="Das Tagebuch weiterschreiben" />
      <Stack>
        <Heading as="h1" level={2}>
          Das Tagebuch weiterschreiben
        </Heading>
        <Paragraph>
          Wie könnte sich Hölderlin dabei gefühlt haben? Versetze dich in seine
          Situation. Denk daran: Er ist zum ersten Mal allein auf Reisen. Das
          ist aufregend, kann aber auch beängstigend sein.
        </Paragraph>

        <TaskSidebar
          main={
            <Paper>
              <Stack space={6} dividers>
                {previousAnswer &&
                  Object.entries(previousAnswer).map(([id, content]) => (
                    <Columns key={id} alignX="space-between" alignY="center">
                      <Column>
                        <Text size={[3, 4]}>{content}</Text>
                      </Column>
                      <Column width="content">
                        <TextPicker id={id} />
                      </Column>
                    </Columns>
                  ))}
              </Stack>
            </Paper>
          }
          sidebar={
            <Note variant="task">
              Füge neben jeden Abschnitt deiner Geschichte ein Emoji ein. Klicke
              dazu auf das Fragezeichen in der Box dahinter und wähle ein
              passendes Emoji aus.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
